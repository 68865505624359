@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caladea:ital,wght@1,700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Caladea", serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.translucent-grey {
  background-color: rgba(0, 0, 0, 0.5);
}
.frosted-glass {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-shadow: 1px 1px 4px black;
}
.frosted-glass-black {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-shadow: 1px 1px 4px whitesmoke;
}
.whitesmoke-heading {
  color: whitesmoke;
  text-shadow: 2px 2px 4px black;
}
.darkgrey-heading {
  color: black;
}
.top {
  top: o;
}
.bottom {
  bottom: 0;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
